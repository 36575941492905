export default function About() {
  return (
    <MainContainer>
    </MainContainer>
  );
}

const MainContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="relative break-all mt-3 grid place-items-center">
      {children}
    </div>
  );
};
