import React from "react";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
// import useEngine, {COUNTDOWN_SECONDS} from "./hooks/useEngine";
import {isMobile} from 'react-device-detect';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Settings from "./components/pages/Settings";
import Leaderboard from "./components/pages/Leaderboard";
import Contact from "./components/pages/Contact";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Licensing from "./components/pages/Licensing";

const App = () => {
  return (
    <>
    <div className="App">
      <Router>
        {/* TODO menu disappears */}
        {Menu(isMobile)}
        {/* <Menu isMobile={isMobile} /> */}
        <div className="container">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="leaderboard" element={<Leaderboard />} />
            <Route path="settings" element={<Settings />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="licensing" element={<Licensing />} />
            <Route path="contact" element={<Contact />} />
        </Routes>
        </div>
        <Footer />
      </Router>
    </div>
    </>
  );
};

export default App;