import React, {useState} from "react";
import logo from '../assets/logo-black.png'
import Toggle from './Toggle'
import { Link } from "react-router-dom";

import { MdKeyboard, MdLeaderboard, MdInfo, MdSettings } from "react-icons/md";

function Menu(isMobile: boolean) {
  const [isKeyboardOn, setIsKeyboardOn] = useState(false);

  const onToggleKeyboard = () => {
    if (!isMobile) {
      setIsKeyboardOn(!isKeyboardOn);


    }
  }

  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="https://type.eeffoc.coffee/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-auto bg-transparent bg-clip-content" alt="eeffoc.coffee Logo" />
        </a>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li hidden={isMobile}>
              <Toggle onToggle={onToggleKeyboard} value={isKeyboardOn} className="block py-2 px-3 text-white rounded md:bg-transparent md:p-0 dark:text-white">
                <MdKeyboard />
              </Toggle>
            </li>
            <li>
              <Link to="leaderboard" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                <MdLeaderboard />
              </Link>
            </li>
            <li>
              <Link to="about" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                <MdInfo />
              </Link>
            </li>
            <li>
              <Link to="about" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                <MdSettings />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
