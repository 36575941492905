import GeneratedWords from "../GeneratedWords";
import RestartButton from "../RestartButton";
import Results from "../Results";
import UserTypings from "../UserTypings";
import { calculateAccuracyPercentage, calculateWordPerMinute } from "../../utils/helpers";
import useEngine, {COUNTDOWN_SECONDS} from "../../hooks/useEngine";

export default function Home() {
  const { words, typed, timeLeft, errors, state, restart, totalTyped } = useEngine();

  return (
    <MainContainer>
    <CountdownTimer timeLeft={timeLeft} />
    <WordsContainer>
      <GeneratedWords key={words} words={words} />
      {/* User typed characters will be overlayed over the generated words */}
      <UserTypings
        className="absolute inset-0"
        words={words}
        userInput={typed}
      />
    </WordsContainer>
    <RestartButton
      className={"mx-auto mt-10 text-slate-500"}
      onRestart={restart}
    />
    <Results
      className="mt-10"
      state={state}
      errors={errors}
      accuracyPercentage={calculateAccuracyPercentage(errors, totalTyped)}
      total={totalTyped}
      wpm={calculateWordPerMinute(COUNTDOWN_SECONDS, errors, totalTyped)}
      />
    </MainContainer>
  );
}

const MainContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="relative break-all mt-3 grid place-items-center">
      {children}
    </div>
  );
};

const WordsContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="relative text-3xl max-w-7xl leading-relaxed break-all mt-3">
      {children}
    </div>
  );
};

const CountdownTimer = ({ timeLeft }: { timeLeft: number }) => {
  return <h2 className="text-primary-400 font-medium">Time: {timeLeft}</h2>;
};