import { useRef } from "react";

const Toggle = ({
  onToggle: handleToggle,
  className = "",
  children = null,
  value = false,
}: {
  onToggle: () => void;
  className?: string;
  children: React.ReactNode;
  value: boolean;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    handleToggle();
  };

  return (
    <button
      ref={buttonRef}
      className={`block rounded px-8 py-2 hover:bg-slate-700/50  ${className} ${value ? "md:dark:text-blue-500" : ""}`} // 
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Toggle;
